import React, { useCallback, useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import microphoneSlash from "../../../../assets/image/microphone_slash.svg";
import microphone from "../../../../assets/image/microphone.svg";
import endCall from "../../../../assets/image/end_call.svg";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import apiCall from "../../../../utils/axiosCall";
import { useParams } from "react-router-dom";
import { endPoint } from "../../../../utils/endPoint";
import welcome_Message from "../../../../assets/audio/welcome.json";

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

const Video = ({
  pauseRecord,
  startRecord,
  pauseRecording,
  resumeRecording,
  recordingPermission,
  stopDisable,
  stop,
  setChat,
  chat,
  setMicrophone,
  setWriteAi,
  setAiAudioStream,
  callcut,
  handle,
  setIsFullScreen,
  setPopupendCall,
  setCurrentInterviewStatus,
}) => {
  let { uuid } = useParams();
  const audioRef = useRef(new Audio());
  const webcamRef = useRef(null);
  const [message, setMessage] = React.useState("");
  const [sessionID, SetSessionID] = useState(
    "763a9ec4-bc43-4770-8436-ff09b5e94ba5"
  );
  const [conversationData, SetConversationData] = useState();
  let audio = new Audio();
  const [aiAudioPlay, setAiAudioPlay] = useState(false);
  const [currentChat, setCurrentChat] = useState([]);
  const [isCurrentConversationFetched, setIsCurrentConversationFetched] =
    useState(false);
  let count = 0;
  let interviewStatus = localStorage.getItem("interview_status");
  const current_session_id = localStorage.getItem("session_id");
  var interview_timing_id = localStorage.getItem("interview_timing_id", -1);

  const [isInactive, setIsInactive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [audioSource, setAudioSource] = useState(false);
  const [finalConnentDetail, setFinalConnentDetail] = useState("");
  const finalTranscriptTimeOut = 5000;
  const inactivityTimerRef = useRef(null);
  var inactivityTimeOut = 50000;
  var popupTimeOut = 60000;
  var showPopupTimeOut = 60000;

  let inactivityTimer;
  let popupTimer;
  let showPopupTimer;

  const resetTimer = useCallback(() => {
    setIsInactive(false);
    setShowPopup(false);
    clearTimeout(inactivityTimer);
    clearTimeout(popupTimer);
    clearTimeout(showPopupTimer);
    startTimers();
  }, []);

  const startTimers = () => {
    inactivityTimer = setTimeout(() => {
      setIsInactive(true);
    }, inactivityTimeOut);

    popupTimer = setTimeout(() => {
      setShowPopup(true);
      startPopupAlertTimer();
    }, popupTimeOut);
  };

  const startPopupAlertTimer = () => {
    showPopupTimer = setTimeout(() => {
      handleEndCallClick();
    }, showPopupTimeOut);
  };

  useEffect(() => {
    startTimers();
    return () => {
      clearTimeout(showPopupTimer);
      clearTimeout(inactivityTimer);
      clearTimeout(popupTimer);
    };
  }, [resetTimer]);

  const handlePopupResponse = (response) => {
    if (response === "yes") {
      clearTimeout(showPopupTimer);
      resetTimer();
    } else {
      handleEndCallClick();
    }
  };

  function base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    hours = String(hours).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const getConversationData = async (userAnswer) => {
    clearTimeout(popupTimer);
    startTimers();
    const currentQuestionId = parseInt(
      localStorage.getItem("autoscreen_curr_que")
    );
    if (currentQuestionId) {
      const now = new Date();
      const formattedTime = formatDate(now);

      let answerData = {
        question_id: currentQuestionId,
        answer: finalConnentDetail,
        answer_timestamp: formattedTime ? formattedTime : "",
      };
      const JsonData = {
        url: endPoint.AUTOSCREEN_UPDATE_ANSWER,
        method: "post",
        data: JSON.stringify(answerData),
      };

      const answerResponse = await apiCall(JsonData);
      const autoscreen_next_id = localStorage.getItem("autoscreen_next_que");
      if (autoscreen_next_id === "null") {
        handleEndCallClick();
        return;
      }
    }
    let conversationData = {
      session_id: current_session_id,
      question_id: parseInt(localStorage.getItem("autoscreen_next_que")) || -1,
    };

    const JsonData = {
      url: endPoint.AUTOSCREEN_GET_QUESTIONS,
      method: "post",
      data: JSON.stringify(conversationData),
    };
    var apiResponse = await apiCall(JsonData);
    if (apiResponse?.return_code === "") {
    }
    SetConversationData(apiResponse?.result);
    localStorage.setItem(
      "autoscreen_next_que",
      apiResponse?.result?.next_question_id
    );
    localStorage.setItem(
      "autoscreen_curr_que",
      apiResponse?.result?.question_id
    );
    localStorage.setItem(
      "interview_status",
      apiResponse?.result?.interview_status
    );
    if (interview_timing_id !== apiResponse?.result?.interview_timing_id) {
      localStorage.setItem(
        "interview_timing_id",
        apiResponse?.result?.interview_timing_id
      );
    }
    clearTimeout(popupTimer);
    resetTimer();
    return apiResponse;
  };
  var countInterview = 1;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let recordTimeData = {
  //         session_id: current_session_id,
  //         interview_timing_id: interview_timing_id,
  //         end_time: new Date().toISOString(),
  //       };

  //       const JsonData = {
  //         url: endPoint.RECORD_INTERVIEW_TIME,
  //         method: "post",

  //         data: JSON.stringify(recordTimeData),
  //       };
  //       var InterviewTimingValueResponse = await apiCall(JsonData);
  //       if (
  //         InterviewTimingValueResponse?.return_code ===
  //         "fetched_interview_timing_success"
  //       ) {
  //         if (
  //           InterviewTimingValueResponse?.result?.interview_timing_id !==
  //           interview_timing_id
  //         ) {
  //           localStorage.setItem("interview_timing_id", interview_timing_id);
  //         }
  //       } else {
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   const intervalId = setInterval(() => {
  //     fetchData();
  //   }, 1000 * 5); // 1000 milisecond * 60 times = 1 minute
  //   resetTimer();
  //   return () => clearInterval(intervalId);
  // }, []);
  useEffect(() => {
    var getSessionData = async () => {
      try {
        const JsonData = {
          url: `${endPoint.GET_SESSION_INFO}${uuid}`,
          method: "get",
        };
        const sessionValue = await apiCall(JsonData);
        localStorage.setItem("session_id", sessionValue.result.session_id);
        localStorage.setItem("uuid", sessionValue.result.interview_id);
        localStorage.setItem("interview_id", sessionValue.result.interview_id);
        localStorage.setItem(
          "interview_status",
          sessionValue.result.interview_status
        );
      } catch (error) {
        const setInterviewStatus = localStorage.setItem(
          "interview_status",
          "completed"
        );
        setPopupendCall(true);
        setIsFullScreen(false);
        handle.exit();
      }
    };

    if (!current_session_id) {
      getSessionData();
    }

    const checkInterviewStatus = async () => {
      if (interviewStatus === "not_started") {
        setIsCurrentConversationFetched(true);
      }
      if (interviewStatus === "in_progess") {
        // load chat
        let data = {
          session_id: current_session_id,
          interview_type: localStorage.getItem("resourceful_interview_type"),
        };

        const JsonData = {
          url: endPoint.AUTOSCREEN_FETCH_CURRENT_CONVERSATION,
          method: "post",
          data: JSON.stringify(data),
        };
        var apiResponse = await apiCall(JsonData);

        if (
          apiResponse.return_code === "fetched_current_conversation_success"
        ) {
          const output_data = [];
          let chatData = apiResponse?.result?.conversation_data;

          if (chatData) {
            chatData.forEach((item) => {
              // Create the 'ai' type object for the question
              const questionObj = {
                type: "ai",
                message: item.question,
                createdAt: item.formatted_question_timestamp,
                questionID: item.question_id,
              };
              output_data.push(questionObj);
              // Create the 'user' type object for the answer
              if (item.answer) {
                const answerObj = {
                  type: "user",
                  message: item.answer,
                  createdAt: item.formatted_answer_timestamp,
                  questionID: item.question_id,
                };

                // Push both objects into the output_data array
                output_data.push(answerObj);
              }
            });
            localStorage.setItem(
              "autoscreen_next_que",
              apiResponse?.result?.next_question_id
            );

            let last_object = output_data.slice(-1)[0];
            localStorage.setItem("autoscreen_curr_que", last_object.questionID);
            if (last_object?.type === "ai") {
              const getTextAudioData = async () => {
                let data = {
                  question_id: last_object.questionID,
                  text: last_object.message,
                  interview_type: localStorage.getItem(
                    "resourceful_interview_type"
                  ),
                };

                const JsonData = {
                  url: endPoint.FETCH_AUDIO,
                  method: "post",
                  data: JSON.stringify(data),
                };
                var apiResponse = await apiCall(JsonData);
                SetConversationData(apiResponse?.result);

                return apiResponse;
              };

              const fetchData = async () => {
                const response = await getTextAudioData(last_object.message);
                return response;
              };

              const response = await fetchData();

              getStreamAudio(response?.result?.audio);
            }

            setChat(output_data);
            setCurrentChat(output_data);
            count += 1;
            setIsCurrentConversationFetched(true);
          }
        }
      }
    };
    if (countInterview === 1) {
      checkInterviewStatus();
      countInterview += 2;
      listenContinuously();
    }
  }, []);
  useEffect(() => {
    const updateTimeStamp = async (currentChat) => {
      let question_timestamp;
      let answer_timestamp = "";
      let questionID;
      for (let i = chat.length - 1; i >= 0; i--) {
        const message = chat[i];
        if (message.type === "user") {
          answer_timestamp = message.createdDateTimeAt;
        }

        if (message.type === "ai") {
          question_timestamp = message.createdDateTimeAt;
          questionID = message.questionID;
        }

        if (questionID && question_timestamp) {
          break;
        }
      }
      if (questionID && question_timestamp) {
        let data = {
          question_id: questionID,
          question_timestamp: question_timestamp,
          answer_timestamp: answer_timestamp,
          interview_type: localStorage.getItem("resourceful_interview_type"),
        };
        const JsonData = {
          url: endPoint.UPDATE_TIMESTAMP,
          method: "post",
          data: JSON.stringify(data),
        };
        var apiResponse = await apiCall(JsonData);
        return;
      }
    };
    updateTimeStamp(currentChat);
  }, [currentChat]);

  // };
  const getCurrentTime = () => {
    const date = new Date();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Check if the time is in 12-hour format
    let timeFormat = "24";
    let meridiem = "";
    if (hours > 12) {
      hours -= 12;
      meridiem = "PM";
      timeFormat = "12";
    } else if (hours === 0) {
      hours = 12;
      meridiem = "AM";
      timeFormat = "12";
    } else {
      meridiem = "AM";
    }

    // Add leading zeros if necessary
    hours = hours < 10 ? `0${hours}` : hours;
    const formattedTime = `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${formattedTime} ${
      timeFormat === "12" ? meridiem : "AM"
    }`;
  };
  const getTimeOnly = (dateTimeStr) => {
    return dateTimeStr.split(" ")[1] + " " + dateTimeStr.split(" ")[2];
  };

  const videoConstraints = {
    width: 2000,
    height: 920,
    facingMode: "user",
  };

  const commands = [
    {
      command: "reset",
      callback: () => resetTranscript(),
    },
    {
      command: "shut up",
      callback: () => setMessage("I wasn't talking."),
    },
    {
      command: "Hello",
      callback: () => setMessage("Hi there!"),
    },
  ];

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });
  // console.log('transcript', transcript);
  useEffect(() => {
    if (callcut === true) {
      handleEndCallClick();
    }
  }, [callcut]);

  const finalTimers = useCallback(() => {
    console.log(finalConnentDetail);
    setMicrophone(false);
    const datetime = getCurrentTime();
    const time = getTimeOnly(datetime);
    // const newData = Array.isArray(chat)
    //   ? [
    //       ...chat,
    //       {
    //         type: "user",
    //         message: finalConnentDetail,
    //         createdAt: time,
    //         createdDateTimeAt: datetime,
    //       },
    //     ]
    //   : [finalConnentDetail];
    // setChat(newData);
    // setCurrentChat(newData);
    if (Array.isArray(chat)) {
      chat.push({
        type: "user",
        message: finalConnentDetail,
        createdAt: time,
        createdDateTimeAt: datetime,
      });
      setChat([...chat]); // Ensure state update with a copy of the modified array
      setCurrentChat([...chat]);
    } else {
      setChat([finalConnentDetail]);
      setCurrentChat([finalConnentDetail]);
    }
    resetTranscript();
    console.log("hello 122->", finalConnentDetail);
    setWriteAi(true);
    const fetchData = async () => {
      const response = await getConversationData(finalConnentDetail);
      return response;
    };

    setTimeout(async () => {
      try {
        const response = await fetchData();
        const AiQuestion = response?.result?.question;
        const questionID = response?.result?.question_id;
        const datetime = getCurrentTime();
        const time = getTimeOnly(datetime);
        if (response.return_code === "interview_started_sucess") {
          if (Array.isArray(chat)) {
            chat.push({
              type: "ai",
              message: AiQuestion,
              createdAt: time,
              questionID: questionID,
              createdDateTimeAt: datetime,
            });
            setChat([...chat]); // Update state with a copy to trigger re-render
            setCurrentChat([...chat]);
          } else {
            const NewQuestion = [
              {
                type: "ai",
                message: AiQuestion,
                createdAt: time,
                questionID: questionID,
                createdDateTimeAt: datetime,
              },
            ];
            setChat(NewQuestion);
            setCurrentChat(NewQuestion);
          }
        }

        setWriteAi(false);
        let audioContent;
        console.log(response);
        if (response.return_code === "interview_started_sucess") {
          audioContent = response?.result?.response_audio;
        } else {
          audioContent = null;
        }
        getStreamAudio(audioContent);
        if (listening) setMicrophone(true);
        if (
          response?.result?.interview_status &&
          response?.result?.interview_status == "completed"
        ) {
          await handleEndCallClick();
          if (webcamRef.current) {
            webcamRef.current = null;
          }
          SpeechRecognition.stopListening();
          setAiAudioPlay(false);
          stop();
        }
      } catch (error) {
        console.log(error);
      }
    }, 2000);
  }, [finalConnentDetail]);

  const resetFinalTimer = useCallback(() => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(() => {
      finalTimers();
    }, finalTranscriptTimeOut);
  }, [finalTimers]);

  useEffect(() => {
    if (finalConnentDetail !== "") {
      resetFinalTimer();
    }

    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [resetFinalTimer]);

  useEffect(() => {
    if (finalTranscript !== "") {
      setFinalConnentDetail(finalTranscript);

      resetFinalTimer();
    }
    // }
  }, [finalTranscript]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let interviewTimingId = localStorage.getItem("interview_timing_id");
        if (interviewTimingId === undefined || interviewTimingId === null) {
          interviewTimingId = -1;
        }
        console.log("## interviewTimingId##", interviewTimingId);
        let recordTimeData = {
          session_id: current_session_id,
          // interviewTimingId :localStorage.getItem("interview_timing_id") !== undefined ? localStorage.getItem("interview_timing_id") : -1,
          interview_timing_id: interviewTimingId,
          end_time: new Date().toISOString(),
        };
        // debugger;
        const JsonData = {
          url: endPoint.AUTOSCREEN_RECORD_INTERVIEW_TIME,
          method: "post",

          data: JSON.stringify(recordTimeData),
        };
        var InterviewTimingValueResponse = await apiCall(JsonData);
        if (
          InterviewTimingValueResponse?.return_code ===
          "fetched_interview_timing_success"
        ) {
          if (
            InterviewTimingValueResponse?.result?.interview_timing_id !==
            interview_timing_id
          ) {
            // debugger;
            localStorage.setItem(
              "interview_timing_id",
              InterviewTimingValueResponse?.result?.interview_timing_id
            );
          }
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000 * 5); // 1000 milisecond * 60 times = 1 minute
    resetTimer();
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isCurrentConversationFetched) {
        count += 1;
        try {
          setWriteAi(true);
          const response = await getConversationData("Hello, how are you");
          const datetime = getCurrentTime();
          const time = getTimeOnly(datetime);
          SetSessionID(response?.result?.session_id);
          const messageAi = response?.result?.question;
          const questionID = response?.result?.question_id;
          if (Array.isArray(chat)) {
            chat.push({
              type: "ai",
              message: messageAi,
              createdAt: time,
              createdDateTimeAt: datetime,
              questionID: questionID,
            });
            setChat([...chat]); // Update state with a copy of the modified array
            setCurrentChat([...chat]);
          } else {
            setChat([finalTranscript]);
            setCurrentChat([finalTranscript]);
          }

          let audioContent;

          if (response.return_code === "interview_started_sucess") {
            audioContent = response?.result?.response_audio;
          } else {
            audioContent = null;
          }
          getStreamAudio(audioContent);
          setWriteAi(false);
        } catch (error) {
          setWriteAi(false);
          console.error("Error fetching conversation data:", error);
        }
      }
      setWriteAi(false);
    };
    if (startRecord && chat.length === 0) {
      if (window.performance) {
        if (performance.navigation.type === 1) {
        } else {
          if (interviewStatus === "not_started") {
            setMicrophone(false);
            SpeechRecognition.stopListening();
            const byteCharacters = atob(welcome_Message.audioContent);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "audio/mp3" });

            const audioUrl = URL.createObjectURL(blob);
            const audio = new Audio(audioUrl);
            audio.play();
            let audio_time = null;
            audio.addEventListener("loadedmetadata", () => {
              audio_time = audio.duration;
            });
          }
        }
      }

      setTimeout(() => {
        fetchData();
      }, 4000);
    }
  }, [startRecord, isCurrentConversationFetched]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    alert(
      "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
    );
    return null;
  }

  const listenContinuously = () => {
    if (recordingPermission && !pauseRecord) {
      SpeechRecognition.startListening({
        continuous: true,
        language: "en",
      });
      setMicrophone(true);
    } else {
      alert("Your interview will begin after permission to screen record.");
    }
  };

  async function getStreamAudio(audioContent) {
    if (audioContent) {
      SpeechRecognition.stopListening();
      setMicrophone(false);
      let audioContentData = audioContent;
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const destination = audioContext.createMediaStreamDestination();
      const audioData = base64ToArrayBuffer(audioContentData);

      audioContext.decodeAudioData(audioData, (buffer) => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        const gainNode = audioContext.createGain();
        gainNode.gain.value = 1;

        source.connect(gainNode);
        gainNode.connect(audioContext.destination); // Connect to the audio context destination for playback
        gainNode.connect(destination); // Connect to the MediaStreamDestination for recording
        setAiAudioStream(destination.stream);
        source.start(0);
        setAudioSource(source);
        setTimeout(() => {
          setMicrophone(true);
          SpeechRecognition.startListening({
            continuous: true,
            language: "en",
          });
        }, buffer.duration * 1000);
      });
    } else {
    }
  }

  // Function to stop the audio
  function stopAudioValue() {
    if (audioSource) {
      audioSource.stop(); // Stop the source node
      audioContext.close(); // Close the audio context
    }
  }
  function audioOff() {
    SpeechRecognition.stopListening();
    setMicrophone(false);
  }

  // Function to stop audio
  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setAiAudioPlay(false);
    }
  };

  const endInterviewCall = async () => {
    let data = {
      session_id: current_session_id,
      interview_timing_id: parseInt(interview_timing_id),
    };

    const JsonData = {
      url: endPoint.AUTOSCREEN_END,
      method: "post",
      data: JSON.stringify(data),
    };
    var apiResponse = await apiCall(JsonData);
    if (apiResponse?.return_code === "call_ended_success") {
      localStorage.setItem("interview_status", "completed");
    } else {
    }
  };
  const handleEndCallClick = async () => {
    setCurrentInterviewStatus("completed");
    await endInterviewCall();
    if (webcamRef.current) {
      webcamRef.current = null;
    }
    setIsFullScreen(false);
    handle.exit();
    setPopupendCall(true);
    SpeechRecognition.stopListening();
    setAiAudioPlay(false);
    stopAudio();
    stopAudioValue();
    stop();
  };

  const handleClickEndCall = async () => {
    setIsFullScreen(false);
  };

  return (
    <>
      {showPopup && (
        <div className='popup'>
          <div
            className={`fixed inset-0 z-10 transition-opacity ${
              showPopup
                ? "ease-out duration-300 opacity-100"
                : "ease-in duration-200 opacity-0"
            }`}
            aria-labelledby='modal-title'
            role='dialog'
            aria-modal='true'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

            <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
              <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
                <div
                  className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ${
                    showPopup
                      ? "ease-out duration-300 opacity-100 translate-y-0 sm:scale-100"
                      : "ease-in duration-200 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  }`}
                >
                  <div className='bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4'>
                    <div className='sm:flex items-center'>
                      <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                        <svg
                          className='text-red-600 w-6 h-6'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 20 20'
                        >
                          <path
                            stroke='currentColor'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z'
                          />
                        </svg>
                      </div>
                      <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                        <h3
                          className='text-base font-semibold leading-6 text-gray-900'
                          id='modal-title'
                        >
                          Are you still there?
                        </h3>
                        <div className='mt-2'>
                          <p className='text-sm text-gray-500'>
                            By quitting this screen you are terminating your
                            interview.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
                    <button
                      type='button'
                      onClick={() => handlePopupResponse("yes")}
                      className='inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto'
                    >
                      Yes
                    </button>
                    <button
                      type='button'
                      onClick={() => handlePopupResponse("no")}
                      className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <p className="text-black">-
        {transcript}
      </p> */}
      <div className='col-span-3 m-0 p-0'>
        <div className='w-full'>
          <div className='relative'>
            <div className='inset-0 justify-center items-center'>
              <div className='absolute bottom-0 right-1/2 m-3 z-10 flex'>
                <div className='bg-gray-100/50 p-2 rounded-full w-[50px] h-[50px] flex content-center justify-center'>
                  {listening ? (
                    <button type='button' onClick={() => audioOff()}>
                      <span className='relative flex'>
                        <span className='animate-ping absolute inline-flex w-full h-full text-center rounded-full bg-blue opacity-75'></span>
                        <img
                          src={microphone}
                          className='w-[25px] h-[25px]'
                          alt='Muted Microphone'
                        />
                      </span>
                    </button>
                  ) : (
                    <button type='button' onClick={listenContinuously}>
                      <img
                        src={microphoneSlash}
                        className='w-[25px] h-[25px]'
                        alt='Active Microphone'
                      />
                    </button>
                  )}
                </div>

                {startRecord && (
                  <div className='bg-gray-100/50 p-2 rounded-full w-[50px] h-[50px] ml-3 flex content-center justify-center'>
                    <button
                      type='button'
                      onClick={handleClickEndCall}
                      disabled={stopDisable}
                    >
                      <span className='relative flex'>
                        <img
                          src={endCall}
                          className='w-[25px] h-[25px]'
                          alt='Stop Screen Record'
                        />
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <Webcam
                ref={webcamRef}
                className='rounded-2xl video'
                audio={false}
                mirrored={true}
                videoConstraints={videoConstraints}
                onUserMediaError={() => {
                  console.log("error");
                }}
              />
            </div>
            <div className='absolute bottom-0 right-0 p-2.5'>
              <div className='lg:w-[170px] lg:h-[120px] w-[50px] h-[50px] rounded-md border-2 border-[#6baafa] text-white flex items-center justify-center rounded-full bg-[#4d514f] flex-shrink-0 lg:text-3xl text-1xl'>
                <span className='relative flex items-center justify-center'>
                  {aiAudioPlay && (
                    <span className='animate-ping absolute inline-flex w-full h-full text-center rounded-full border-2 border-zinc-500 opacity-75'></span>
                  )}

                  <div className='w-[50px] h-[50px] bg-[#6279ca] content-center rounded-full flex items-center justify-center z-[999]'>
                    RI
                  </div>

                  {aiAudioPlay && (
                    <span className='animate-ping absolute inline-flex w-[35px] h-[35px] text-center rounded-full bg-gray-500'></span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
