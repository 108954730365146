import React, { useEffect, useState } from "react";
import { generatePath, useLocation } from "react-router-dom";
import Header from "../header";
import thankYou_Message from "../../assets/audio/thankyou.json";
import videoCall_thankyou from "../../assets/audio/video_call_thankyou.json";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [isLoading, setIsLoading] = useState(true);
  const userType = localStorage.getItem("userType");
  const [thankyouText, setThankyouText] = useState("Thank You");
  const [flag, setFlag] = useState(false)
  const audioPlayedRef = useRef(false); 

  useEffect(() => {
    // Set a timeout to simulate the reloading process
    const timeout = setTimeout(() => {
      console.log("WAITINGs");
      setIsLoading(false);
      setThankyouText("Thank You");
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    if (isLoading) {
      // if (window.performance) {
      //   console.log("performance", performance);
      //   if (performance.navigation.type === 1) {
      //     console.log("s");
    } else {
      if(userType !== 'staff') { 
        let audioContent;
        if (location.state?.from === "VideoCall" ||location.state?.from === "External-interview") {
          audioContent = videoCall_thankyou.audioContent;
        } else if(location.state?.from === "autoscreen" ||location.state?.from === "interview") {
          audioContent = thankYou_Message.audioContent;
        }
        if(audioContent && !audioPlayedRef.current)
          {
            audioPlayedRef.current = true;
             playAudio(audioContent);
            }
      } else {
        if(location.state) {
          navigate(`/thank-you/`, { replace: true });
          // window.location.reload();
        }
      }
    }
        //   }
        // }
  }, [isLoading, location.state]);

  const playAudio = (audioContent) => {
    if(!flag){
    const byteCharacters = atob(audioContent);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(blob);
    const audio = new Audio(audioUrl);
    
    audio.addEventListener("ended", () => {
      if(location.state && !flag) {
        setFlag(true)
        navigate(`/thank-you/`, { replace: true });
        window.location.reload();
      }
    });

    audio.play();
  }
  };

  return (
    <div>
      <Header />
      <div className='video-preview rounded-2xl justify-center items-center flex lg:h-[93vh] h-[90vh] antialiased text-gray-800'>
        <div className='text-center py-4 lg:px-4'>
          <div
            className='flex lg:inline-flex border-t-4 border-lime-500 rounded-b text-lime-900 px-4 py-3 shadow-md'
            role='alert'
          >
            <div className='flex'>
              <p className='font-bold'>{thankyouText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
